import { CONFIGURATION_ALERT_CONSTANTS, URL as APP_URL } from '../constants'
import { navigateToUrl } from 'single-spa'
import { updatePerformanceManagementSelectedTab } from './rxjsStateNext'
import { config, globalstate$ } from '@smf/ui-util-app'

export const handleExploreNavigation = (functionalArea) => {
  switch (functionalArea) {
    case CONFIGURATION_ALERT_CONSTANTS.FUNCTIONAL_AREAS.FACTORY_HEALTH:
      updatePerformanceManagementSelectedTab(0)
      navigateToUrl(APP_URL.FACTORY_HEALTH)
      break
    case CONFIGURATION_ALERT_CONSTANTS.FUNCTIONAL_AREAS.OEE:
      updatePerformanceManagementSelectedTab(1)
      navigateToUrl(APP_URL.FACTORY_HEALTH)
      break
    case CONFIGURATION_ALERT_CONSTANTS.FUNCTIONAL_AREAS.EQUIPMENT_CONSTRAINT:
      navigateToUrl(APP_URL.EQUIPMENT_CONSTRAINT)
      break
    case CONFIGURATION_ALERT_CONSTANTS.FUNCTIONAL_AREAS
      .STANDARD_OPERATING_CONDITIONS:
      navigateToUrl(APP_URL.STANDARD_OPERATING_CONDITIONS)
      break
    default:
      navigateToUrl(APP_URL.GLOBE)
      break
  }
}

export const formatTime = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2)
  const minutes = `${Math.floor(timer / 60)}`
  const getMinutes = `0${minutes % 60}`.slice(-2)
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

  return `${getHours}:${getMinutes}:${getSeconds}`
}

const getSessionObj = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem(key)) ?? {};
  } catch (error) {
    console.error('Error while parsing sessionStorage', error);
    return {};
  }
};

export const redirectInApp = () => {
  /**
   * If the user is redirected from v2 app
   */
  if (sessionStorage.getItem('redirectToOld')) {
    const {
      url,
      factoryId,
      plantName,
      businessUnit,
      businessUnitId,
      isConfigPage,
    } = getSessionObj('redirectToOld');
    /**
     * For the first time, handle the redirection from v2 app using this flag
     */
    if (factoryId || isConfigPage) {
      globalstate$.next({
        ...globalstate$.value,
        factoryId,
        plantId: factoryId,
        plantName,
        businessUnit,
        businessUnitId,
        isFGCComplete: true,
      });
      /**
       * Clearing the first time flags, so that redirectTo session will take care of the rest
       */
      sessionStorage.setItem('redirectToOld', JSON.stringify({ url }));
      navigateToUrl(new URL(url).pathname);
      return;
    }
  }
  /**
   * Handle redirection within the app
   */
  if (sessionStorage.getItem('redirectTo')) {
    const { callbackPath = '/', stateSnap = {} } = getSessionObj('redirectTo');
    globalstate$.next({
      ...globalstate$.value,
      ...stateSnap,
    });
    navigateToUrl(callbackPath);
  }
};

export const navigateToNewApp = () => {
  /**
   * Clearing the redirection flags within old app and v2toOld app
   */
  sessionStorage.removeItem('redirectToOld');
  sessionStorage.removeItem('redirectTo');
  /**
   * If we are in any config page, we need to navigate to portfolio menu
   * And set the redirectInV2 flag with selected plant details
   */
  if (window.location.pathname.startsWith("/config/")) {
    const { stateSnap = {} } = getSessionObj('redirectInV2');
    sessionStorage.setItem(
      'redirectInV2',
      JSON.stringify({
        callbackPath: '/portfolio_menu',
        stateSnap: {
          ...stateSnap,
          factoryId: globalstate$.value.factoryId,
          plantName: globalstate$.value.plantName,
          businessUnit: globalstate$.value.businessUnit,
          businessUnitId: globalstate$.value.businessUnitId,
        },
      })
    );
  } else {
    /**
     * Else, we need to navigate to plant listing
     * And remove the redirectInV2 flag to avoid redirection to any other page
     */
    sessionStorage.removeItem('redirectInV2');
  }
  window.location = config.NEW_APP_URL;
};
