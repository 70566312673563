import { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Toolbar,
  IconButton,
  Typography,
  Box,
  Menu,
  MenuItem,
  Button,
  Badge,
  ClickAwayListener,
  Backdrop,
  Link,
} from "@mui/material";
import PropTypes from "prop-types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import DeloitteLogo from "../../assets/img/Deloitte.svg";
import BuildingOffice from "../../assets/img/buildingoffice.svg";
import PlantFactory from "../../assets/img/plantFactory.svg";
import EditIcon from "../../assets/img/editIcon.svg";
import ArrowDown from "../../assets/img/arrowDown.svg";
import ArrowRight from "../../assets/img/arrowRight.svg";
import LogoutButton from "../../assets/img/logoutButton.svg";
import Bell from "../../assets/img/bell.svg";
import {
  RBAC_CONSTANTS,
  TOAST_REDUCER_CONSTANTS,
  PROFILE_DROPDOWN_OPTIONS,
  THREE_DOTS_DROPDOWN_OPTIONS,
  DDS_DEMO_STATES,
  QUERY_KEYS,
  DDS_DEMO_CONTROLS,
  APP_URL,
} from "../../constants";
import CompanyNameDialog from "./CompanyNameDialog";
import MESSAGE_STRINGS, { CONFIRMER_MESSAGES } from "../../constants/en-us";
import { useToastContext } from "../../context/toastContext";
import {
  putCompanyName,
  getCompanyDetails,
  getRunningDemoDetails,
  configureDemoState,
} from "../../utils/apiHelper";
import { useRxjsState } from "../../utils/hooks/useRxjsState";

import {
  updateFactoryGeneralEditState,
  updateRxjsState,
} from "../../utils/rxjsStateNext";
import {
  config,
  RBAC_BUTTONS,
  RBACValidation,
  URL,
  globalstate$,
  updateEditState,
  EDIT_STATE_KEYS,
  DDS_CONSTANTS,
} from "@smf/ui-util-app";
import useSOCWebsocketListener from "../../utils/hooks/useSOCWebsocketListener";
import useNotificationsListener from "../../utils/hooks/useNotificationsListener";
import NotificationCenterModal from "../../containers/NotificationCenter/index";
import ElevationScroll from "./ElevationScroll";
import DemoControlModule from "./DemoControlModule";
import {
  AppBarStyle,
  ToolbarStyle,
  TypographyTitleCenter,
  TypographyUserText,
  LightTooltip,
} from "./style";
import PushNotifications from "../../containers/PushNotifications";
import useWssListener from "../../utils/hooks/useWssListener";
import {
  onDemoReady,
  onDemoReadyFailed,
} from "../../utils/wssHelperFunction/wssDdsHelper";
import { NOTIFICATION_REDUCER_CONSTANTS } from "../../constants/index";
import GeneralButton from "../Button";
import { navigateToNewApp } from "../../utils/helper";
import IntelligentOps from "../../assets/img/IntelligentOpsLogo.svg";
import styled from "@emotion/styled";
import CookieNoticeIcon from "../../assets/img/cookieNoticeIcon.svg";
import ThreeDotsIcon from "../../assets/img/threeDotsIcon.svg";
import Confirmer from "../Confirmer";

const IOPsLogo = styled("img")(() => ({
  height: "1.5rem",
  width: "100%",
  marginLeft: "1rem",
  marginTop: "0.4rem",
}));

const BrandLogo = styled("img")(() => ({
  padding: "0rem 1rem",
  borderRight: "solid 2px",
}));

const StyledHeaderName = styled(Typography)(({ theme }) => ({
  display: "flex",
  width: "20rem",
}));

const Header = (props) => {
  const userMenuSettings = [
    {
      title: PROFILE_DROPDOWN_OPTIONS.LOGOUT,
      iconComponent: LogoutButton,
      url: "/logout",
    },
  ];
  const threeDotsMenu = [
    {
      title: THREE_DOTS_DROPDOWN_OPTIONS.COOKIES,
      iconComponent: CookieNoticeIcon,
      externalUrl: true,
      url: "https://cookienotice.deloitte.com",
    },
  ];
  const { rxjsState } = useRxjsState();
  const {
    companyDetails,
    userStatus,
    userName,
    userRole,
    loggedUserEmailId,
    ...other
  } = props; // userInformation { userName: "", userRole: { permission: "System Admin", level: "GLOBAL" }, loggedUserEmailId: ""
  const { toastDispatch } = useToastContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElDots, setAnchorElDots] = useState(null);

  const [companyName, setCompanyName] = useState({
    value: rxjsState.companyData?.companyName,
    error: "",
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [sessionToken, setSessionToken] = useState("");

  const headerRef = useRef(null);
  // local state for RBAC
  const [addEditCompanyAccess, setAddEditCompanyAccess] = useState(false);

  const [showDemoControlModule, setShowDemoControlModule] = useState(false);
  const [currentDemoDetails, setCurrentDemoDetails] = useState({});
  const [timeFromTimerInSeconds, setTimeFromTimerInSeconds] = useState(0);

  const queryClient = useQueryClient();
  const location = useLocation();

  const { socEditState: { action: socAction = {}, isUnsavedRecipe } = {} } =
    rxjsState;
  const isSameLocation = socAction.location === APP_URL.SOC_CONFIG;
  const [showConfirmer, setShowConfirmer] = useState(false);

  const confirmYesButtonAction = () => {
    updateEditState(
      { isUserNavigating: false, location: "" },
      { isUnsavedRecipe: false },
      EDIT_STATE_KEYS.SOC_EDIT_STATE
    );
    navigateToNewApp();
    setShowConfirmer(false);
  };

  const confirmNoButtonAction = () => {
    updateEditState(
      { isUserNavigating: false, location: "" },
      {},
      EDIT_STATE_KEYS.SOC_EDIT_STATE
    );
    setShowConfirmer(false);
  };

  useEffect(() => {
    // adding default userInformation as static, Todo: need to get the loggedIn user details
    updateRxjsState({
      userInformation: {
        userName: "",
        userRole: { permission: "System Admin", level: "GLOBAL" },
        loggedUserEmailId: "",
      },
    });
    globalstate$.subscribe((globalstate) => {
      setSessionToken(globalstate.sessionToken);
    });
    // getting companyName from the API, and updating the rxjs Global State.
    async function getCompanyName() {
      const response = await getCompanyDetails();
      const data = {
        companyName: response?.companyName || "",
        companyId: response?.companyId || "",
      };
      updateRxjsState({
        companyData: data,
      });
    }
    getCompanyName();
  }, []);

  const navigateToNewAppHandler = () => {
    if (isUnsavedRecipe && !isSameLocation) {
      setShowConfirmer(true);
    } else {
      navigateToNewApp();
    }
  }

  /**
   * Use effect to invalidate state of header on reload and navigating to globe page
   */
  useEffect(() => {
    filterDemoListByUpdatedUserAndState();
  }, [currentDemoDetails]);

  useEffect(() => {
    if (rxjsState?.ddsLogoutConfig?.isConfirmLogoutClicked) {
      window.location.href = userMenuSettings[0].url;
    }
  }, [rxjsState]);
  /**
   * API call to fetch demo list and filter to show control module or not
   */
  const { isFetching: isDemosListFetching } = useQuery(
    [QUERY_KEYS.GET_RUNNING_DEMO_LIST],
    () => getRunningDemoDetails(),
    {
      onSuccess: (response) => {
        // Invalidate demo if more than one is capable of being started
        if (!response.data || response.data.length > 1) {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message: MESSAGE_STRINGS["DDS.message.ERROR"],
            },
          });
        } else {
          setTimeFromTimerInSeconds(response.data[0]?.timeElapsedInSeconds);
          setCurrentDemoDetails(response.data[0]);
        }
      },
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  /**
   * Find if any demo is ready to start
   * If there is such a demo, then invalidate the query key and refetch running demos
   * for user
   */
  useEffect(() => {
    if (rxjsState.ddsDemoStatus) {
      // ddsDemoStatus will be something like this {demo 12: "Ready"}

      // Find if any demo is in running state in the array
      const isAnyDemoPreparing = Object.keys(rxjsState.ddsDemoStatus).find(
        (demo) => rxjsState.ddsDemoStatus[demo] === "Ready"
      )
        ? true
        : false;

      // Invalidate query key on true condition
      if (isAnyDemoPreparing) {
        queryClient.invalidateQueries([QUERY_KEYS.GET_RUNNING_DEMO_LIST]);
      }
    }
  }, [rxjsState.ddsDemoStatus]);

  /**
   * Method to filter demo list based on updated user and demo state
   * @param {*} demoList
   */
  const filterDemoListByUpdatedUserAndState = () => {
    // List of invalid states for showing demo control module
    const invalidStates = new Set([
      DDS_DEMO_STATES.CREATED,
      DDS_DEMO_STATES.PREPARING,
    ]);
    // Update the state to show demo control module
    setShowDemoControlModule(
      currentDemoDetails &&
        currentDemoDetails.demoState &&
        !invalidStates.has(currentDemoDetails.demoState)
        ? true
        : false
    );
  };

  // Updating the State whenever the UserStatus changes on rendering
  useEffect(() => {
    setAddEditCompanyAccess(
      RBACValidation("Button", RBAC_BUTTONS["ADD_EDIT_COMPANY"], userStatus) &&
        !rxjsState.plantId
    );
  }, [userStatus, rxjsState.plantId]);

  // Getting Avatar Initials from First And Last Name
  const getAvatarInitials = (firstname, lastName) => {
    if (firstname && lastName) {
      return firstname[0] + lastName[0];
    } else {
      return "  ";
    }
  };

  const {
    isLoadingInitialData: isLoadingNotificationData,
    notificationsState,
    dismissNotification,
    toggleSnooze,
    updateRecurrenceInterval,
    updateSnoozeTimer,
    appendExistingAlert,
    markNotificationAsRead,
    showNotificationCenter,
    showPushNotifications,
    notificationsDispatch,
  } = useNotificationsListener();

  const markAsRead = () => {
    markNotificationAsRead();
    notificationsDispatch({
      type: NOTIFICATION_REDUCER_CONSTANTS.MARK_ALL_AS_READ,
    });
  };

  const handleNotificationCenterClickAway = () => {
    if (rxjsState.plantId) {
      if (notificationsState.showNotificationCenter) {
        if (notificationsState.count > 0) {
          markAsRead();
        }
        showNotificationCenter(!notificationsState.showNotificationCenter);
        showPushNotifications(!notificationsState.showPushNotifications);
      }
    }
  };

  // WebSocketListeners
  useSOCWebsocketListener();
  useWssListener(DDS_CONSTANTS.WSS_CHANNEL, onDemoReady, onDemoReadyFailed);

  const {
    isLoading: isUpdateCompanyNameLoading,
    isError: isUpdateCompanyNameError,
    reset: resetUpdateCompanyName,
    mutate: putUpdateCompanyName,
  } = useMutation(
    (updatedCompanyName) =>
      putCompanyName({
        companyName: updatedCompanyName,
        companyId: companyDetails?.companyId,
      }),
    {
      onSuccess: (response) => {
        if (response) {
          updateRxjsState({
            companyData: {
              companyName: response?.updatedCompanyName,
              companyId: companyDetails?.companyId,
            },
          });
          setAnchorEl(null);
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
            payload: {
              message:
                MESSAGE_STRINGS[
                  "WRAPPER.header.editCompany.companyNameEditSuccess"
                ],
            },
          });
        }
        resetUpdateCompanyName();
      },
      onError: (error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrorMessage(error.response.data.message);
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message:
                MESSAGE_STRINGS[
                  "WRAPPER.header.editCompany.error.duplicateCompanyName"
                ],
            },
          });
        }
      },
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  function handleCancel() {
    setCompanyName((prevDetails) => ({
      ...prevDetails,
      value: rxjsState.companyData.companyName,
      error: "",
    }));
    setIsSaveDisabled(true);
    setAnchorEl(null);
    resetUpdateCompanyName();
    setErrorMessage("");
  }

  function handleEdit() {
    if (anchorEl) {
      handleCancel();
    } else {
      setAnchorEl(headerRef.current);
      setCompanyName({
        value: rxjsState.companyData.companyName,
        error: "",
      });
    }
  }

  function handleSave() {
    if (companyName.value === "") {
      setCompanyName((prevName) => ({
        ...prevName,
        error:
          MESSAGE_STRINGS["WRAPPER.header.editCompany.error.emptyCompanyName"],
      }));
    } else {
      setCompanyName((prevName) => ({
        ...prevName,
        error: "",
      }));
      putUpdateCompanyName(companyName.value);
      setIsSaveDisabled(true);
    }
  }

  function handleCompanyChange(event) {
    const text = event.target.value;
    setIsSaveDisabled(false);
    setCompanyName((prevCompany) => ({
      ...prevCompany,
      value: text,
    }));
  }

  const handleNotificationCenter = () => {
    if (
      notificationsState.showNotificationCenter &&
      notificationsState.count > 0
    ) {
      markAsRead();
    }
    showNotificationCenter(!notificationsState.showNotificationCenter);
    showPushNotifications(!notificationsState.showPushNotifications);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    showPushNotifications(false);
  };
  const handleOpenDotsMenu = (event) => {
    setAnchorElDots(event.currentTarget);
    showPushNotifications(false);
  };

  const handleCloseDotsMenu = () => {
    setAnchorElDots(null);
    showPushNotifications(true);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    showPushNotifications(true);
  };
  const handleMenuItemClick = (url, externalUrl) => {
    handleCloseDotsMenu();
    if (externalUrl) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  const { mutateAsync: configureDemoStateCall } = useMutation(
    [QUERY_KEYS.PUT_CONFIGURE_DEMO_STATE],
    async (data) => {
      const response = await configureDemoState(data);
      return response;
    },
    {
      onError: (error) => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: {
            message: error.message,
          },
        });
      },
    }
  );

  const headerOnlickHandler = async (userMenu) => {
    const ddsLogoutConfiguration = rxjsState?.ddsLogoutConfig;
    updateRxjsState({
      ddsLogoutConfig: {
        ...ddsLogoutConfiguration,
        isLogoutBtnClicked: true,
      },
    });
    {
      if (
        location.pathname === URL.DEMO_DATA_SIMULATOR &&
        globalstate$?._value.ddsNavigateAwayConfig?.isUnsavedChange
      ) {
        updateDDSEventState({
          isUserNavigating: true,
          location: userMenu.url,
        });
        return;
      }
      if (
        location?.pathname === `${URL.CONFIG}${URL.GENERAL_CONFIG}` &&
        rxjsState.factoryGeneralEditState?.isUnsavedChange
      ) {
        updateFactoryGeneralEditState({
          isUserNavigating: true,
          location: userMenu.url,
        });
        return;
      }
      if (
        location?.pathname === `${URL.CONFIG}${URL.SOC_CONFIG}` &&
        rxjsState.socEditState?.isUnsavedRecipe
      ) {
        updateEditState(
          { isUserNavigating: true, location: userMenu.url },
          {},
          EDIT_STATE_KEYS.SOC_EDIT_STATE
        );
        return;
      }
      if (userMenu.title === PROFILE_DROPDOWN_OPTIONS.LOGOUT) {
        updateRxjsState({ plantId: "" });
      }
      if (
        currentDemoDetails.demoState === DDS_DEMO_STATES.IN_PROGRESS ||
        currentDemoDetails.demoState === DDS_DEMO_STATES.PAUSED ||
        currentDemoDetails.demoState === DDS_DEMO_STATES.READY
      ) {
        await configureDemoStateCall({
          demoName: currentDemoDetails.demoName,
          state: DDS_DEMO_CONTROLS.STOP,
          timeElapsedInSeconds: timeFromTimerInSeconds,
        });
        setCurrentDemoDetails((prevDemoState) => {
          return { ...prevDemoState, demoState: DDS_DEMO_STATES.CREATED };
        });
      }
      if (!ddsLogoutConfiguration?.areAnyUnsavedChanges) {
        window.location.href = userMenu.url;
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "spring-popper" : undefined;

  const isGlobalSystemAdmin =
    rxjsState.userInformation?.userRole.permission ===
      RBAC_CONSTANTS.PERMISSIONS.SYSTEM_ADMIN &&
    rxjsState.userInformation?.userRole.level === RBAC_CONSTANTS.LEVELS.GLOBAL;

  return (
    <>
      <ElevationScroll {...props}>
        <AppBarStyle {...other}>
          <ToolbarStyle disableGutters={true}>
            <StyledHeaderName>
              <BrandLogo src={DeloitteLogo} alt="DeloitteLogo" />
              <IOPsLogo src={IntelligentOps} alt="IntelligentOps" />
            </StyledHeaderName>

            {/* Demo Control Module code starts here */}
            {showDemoControlModule && (
              <DemoControlModule
                currentDemoDetails={currentDemoDetails}
                setCurrentDemoDetails={setCurrentDemoDetails}
                timeFromTimerInSeconds={timeFromTimerInSeconds}
                setTimeFromTimerInSeconds={setTimeFromTimerInSeconds}
                configureDemoStateCall={configureDemoStateCall}
              ></DemoControlModule>
            )}

            {/* Demo Control Module code ends here */}

            <Box
              paddingLeft={2}
              paddingRight={2}
              paddingTop={isGlobalSystemAdmin ? 0 : 1.5}
              paddingBottom={isGlobalSystemAdmin ? 0 : 1.5}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              ref={headerRef}
            >
              <>
                {!location.pathname.startsWith("/config/") && (
                  <GeneralButton
                    size="small"
                    onClick={() => {
                      navigateToNewAppHandler();
                    }}
                    data-testid="header-navigation-newui-button"
                    sx={{ "&.MuiButton-root": { minWidth: "6rem" } }}
                  >
                    <strong>
                      {MESSAGE_STRINGS['WRAPPER.header.newui.navigation.text']}
                    </strong>
                  </GeneralButton>
                )}
                {showConfirmer && (
                  <Backdrop open>
                    <Confirmer
                      message={CONFIRMER_MESSAGES.UNSAVED_CHANGES}
                      YesHandler={confirmYesButtonAction}
                      NoHandler={confirmNoButtonAction}
                    />
                  </Backdrop>
                )}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  {...(rxjsState.plantId && {
                    sx: {
                      color: (theme) => theme.palette.text.saveGreen,
                      cursor: "pointer",
                    },
                    onClick: () => {
                      navigateToNewAppHandler();
                    },
                  })}
                >
                  <img
                    src={rxjsState.plantId ? PlantFactory : BuildingOffice}
                    alt="title_icon"
                    style={{ width: "2.75em", height: "2.75rem" }}
                  />
                  <TypographyTitleCenter noWrap>
                    {rxjsState.plantId
                      ? rxjsState.plantName
                      : rxjsState.companyData?.companyName}
                  </TypographyTitleCenter>
                  {rxjsState.plantId && (
                    <img
                      src={ArrowRight}
                      alt="Arrow Right"
                      style={{ width: "2.69rem", height: "2.69rem" }}
                    />
                  )}
                </Box>
                {addEditCompanyAccess &&
                  !location.pathname.includes(URL.ACCESS_CONFIG) && (
                    <IconButton size="small" onClick={handleEdit}>
                      <img src={EditIcon} alt="EditIcon" />
                    </IconButton>
                  )}
                <Box display="flex" justifyContent="center" width="100%">
                  <>
                    {rxjsState.plantId && (
                      <ClickAwayListener
                        onClickAway={handleNotificationCenterClickAway}
                      >
                        <Box paddingLeft={1} paddingRight={1}>
                          <Button onClick={handleNotificationCenter}>
                            <Badge
                              badgeContent={notificationsState.count}
                              color="primary"
                            >
                              <img src={Bell} alt="bellIcon" />
                            </Badge>
                          </Button>
                          {notificationsState.showNotificationCenter && (
                            <NotificationCenterModal
                              closeModal={() => {
                                showNotificationCenter(false);
                                showPushNotifications(true);
                              }}
                              isLoadingNotificationData={
                                isLoadingNotificationData
                              }
                              activeNotifications={
                                notificationsState.notifications
                              }
                              dismissNotification={dismissNotification}
                            />
                          )}
                        </Box>
                      </ClickAwayListener>
                    )}
                  </>
                  <Box display="flex">
                    <IconButton onClick={handleOpenDotsMenu}>
                      <img src={ThreeDotsIcon} alt="Three-dots-Icon" />
                    </IconButton>
                    <Menu
                      anchorEl={anchorElDots}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      open={Boolean(anchorElDots)}
                      onClose={handleCloseDotsMenu}
                    >
                      {threeDotsMenu.map((userMenu) => (
                        <MenuItem
                          key={userMenu["title"]}
                          onClick={() => {
                            handleMenuItemClick(
                              userMenu["url"],
                              userMenu["externalUrl"]
                            );
                          }}
                        >
                          <img src={userMenu.iconComponent} alt="menu-icon" />
                          <LightTooltip
                            title={userMenu["url"]}
                            className="tooltip-menu-item"
                            placement="bottom"
                          >
                            <Typography
                              textAlign="center"
                              gutterBottom={true}
                              sx={{
                                marginLeft: "0.75rem",
                                marginTop: "0.4rem",
                                textDecoration: "underline",
                              }}
                            >
                              {userMenu.title}
                            </Typography>
                          </LightTooltip>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                  <Box
                    bgcolor="background.saveGreen"
                    width="2em"
                    height="2em"
                    borderRadius="1em"
                    alignSelf="center"
                  >
                    <TypographyUserText variant="h5" gutterBottom={true}>
                      {getAvatarInitials(
                        rxjsState.userData?.firstName,
                        rxjsState.userData?.lastName
                      )}
                    </TypographyUserText>
                  </Box>

                  <Box display="flex">
                    <IconButton size="small" onClick={handleOpenUserMenu}>
                      <img src={ArrowDown} alt="ArrowDown" />
                    </IconButton>
                    <Menu
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {userMenuSettings.map((userMenu) => (
                        <MenuItem key={userMenu} onClick={handleCloseUserMenu}>
                          <span style={{ paddingRight: "15px" }}>
                            <img src={userMenu.iconComponent} alt="menu-icon" />
                          </span>
                          <Typography
                            textAlign="center"
                            gutterBottom={true}
                            onClick={() => headerOnlickHandler(userMenu)}
                          >
                            {userMenu.title}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Box>
              </>
            </Box>
          </ToolbarStyle>

          <CompanyNameDialog
            id={id}
            anchorEl={anchorEl}
            open={open}
            companyName={companyName}
            handleCompanyNameChange={handleCompanyChange}
            handleCancel={handleCancel}
            handleSave={handleSave}
            isSaveDisabled={isSaveDisabled}
            loading={isUpdateCompanyNameLoading}
            error={{ isError: isUpdateCompanyNameError, message: errorMessage }}
            placement="bottom-end"
          />
        </AppBarStyle>
      </ElevationScroll>
      <Toolbar />
      {/* Workaround for QA automation - to be removed - SMAR-8332 */}
      <span style={{ display: "none" }} id="qa_automationTest_workaround">
        {sessionToken}
      </span>
      {rxjsState.plantId && notificationsState.showPushNotifications && (
        <PushNotifications
          alertsData={notificationsState.alerts}
          recurrenceIntervals={notificationsState.recurrentAlertIntervals}
          snoozeIntervals={notificationsState.snoozedAlertIntervals}
          dismissNotification={dismissNotification}
          toggleSnooze={toggleSnooze}
          updateRecurrenceInterval={updateRecurrenceInterval}
          updateSnoozeTimer={updateSnoozeTimer}
          appendExistingAlert={appendExistingAlert}
        />
      )}
    </>
  );
};

Header.propTypes = {
  userName: PropTypes.string.isRequired,
  userRole: PropTypes.shape({
    businessFunction: PropTypes.string,
    businessUnit: PropTypes.string,
    isDefault: PropTypes.bool,
    landingPage: PropTypes.string,
    level: PropTypes.string,
    permission: PropTypes.string,
    role: PropTypes.string,
    roleAssignmentEndDate: PropTypes.string,
    roleAssignmentStartDate: PropTypes.string,
    roleId: PropTypes.number,
    unitId: PropTypes.string,
  }),
  userStatus: PropTypes.number,
  loggedUserEmailId: PropTypes.string,
  companyDetails: PropTypes.shape({
    companyName: PropTypes.string,
    companyId: PropTypes.string,
  }),
};

export default Header;
