import MESSAGE_STRINGS from './en-us';

export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const PROFILE_DROPDOWN_OPTIONS = {
  PROFILE: 'Profile',
  LOGOUT: 'Logout',
};
export const THREE_DOTS_DROPDOWN_OPTIONS = {
  COOKIES: 'Cookies',
}
export const HEADER_TABS = {
  HELP: 'Help',
  NOTIFICATIONS: 'Notifications',
  PROFILE: 'Profile',
};

export const MAX_INPUT_SIZE = 5;

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};

export const NOTIFICATION_REDUCER_CONSTANTS = {
  DISMISS: 'DISMISS',
  DISMISS_PUSH_NOTIFICATION: 'DISMISS_PUSH_NOTIFICATION',
  APPEND: 'APPEND',
  RESET: 'RESET',
  INITIALIZE: 'INITIALIZE',
  APPEND_EXISTING_ALERT: 'APPEND_EXISTING_ALERT',
  UPDATE_RECURRENCE_INTERVAL: 'UPDATE_RECURRENCE_INTERVAL',
  UPDATE_SNOOZE_TIMER: 'UPDATE_SNOOZE_TIMER',
  MARK_AS_SNOOZED: 'MARK_AS_SNOOZED',
  MARK_ALL_AS_READ: 'MARK_ALL_AS_READ',
  TOGGLE_NOTIFICATION_CENTER: 'TOGGLE_NOTIFICATION_CENTER',
  TOGGLE_PUSH_NOTIFICATIONS: 'TOGGLE_PUSH_NOTIFICATIONS',
};

export const NOTIFICATION_CENTER_CONSTANTS = {
  TAB_ITEMS: [
    {
      label: 'Functional Area',
      value: 0,
      disabled: false,
    },
    {
      label: 'Notification Type',
      value: 1,
      disabled: false,
    },
  ],
};

export const NOTIFICATION_UPDATE_TYPES = {
  DISMISS: 'dismissed',
  SNOOZE: 'snoozed',
  EXPLORE: 'explore',
  UNSNOOZE: 'unsnoozed',
};

export const NOTIFICATION_TYPES = {
  ALERT: 'Alert',
  BANNER: 'Banner',
  NC_ONLY: 'Notification Center Only',
};

export const NOTIFICATION_DURATION_UOM = {
  HOURS: 'Hours',
  MINUTES: 'Minutes',
};

export const CONFIGURATION_ALERT_CONSTANTS = {
  FUNCTIONAL_AREAS: {
    FACTORY_HEALTH: 'Factory Health',
    OEE: 'Overall Equipment Effectiveness',
    EQUIPMENT_CONSTRAINT: 'Equipment Constraint',
    DOM: 'Dynamic Order Mgmt.',
    MANUFACTURING_EQUIPMENT: 'Manufacturing Equipment',
    BUILDING_EQUIPMENT: 'Building Equipment',
    STANDARD_OPERATING_CONDITIONS: 'Standard Operating Conditions',
  },
};

export const SOCKET_DATA_TYPES = {
  ALERT: 'Alert',
  BANNER: 'Banner',
  NC_ONLY: 'Notification Center Only',
  CONFIGURATION_STATUS: 'configurationStatus',
  CONFIGURATION_STATUS_UPDATE: 'configurationUpdate',
};

export const URL = {
  FACTORY_HEALTH: '/performance_management',
  GLOBE: '/',
  EQUIPMENT_CONSTRAINT: '/equipment_management',
  STANDARD_OPERATING_CONDITIONS: '/quality_management',
};

// Review the constants below and delete unused ones
export const DELETE_BUTTON = 'deleteButton';

export const FILE_ACCEPT_TYPES = {
  CSV: '.csv, text/csv',
  IMAGE_PNG_JPEG: '.jpeg,.png,.jpg',
};
export const FILE_CONTENT_TYPES = {
  CSV: 'text/csv',
};

export const FILE_UPLOAD_STATUSES = {
  DONE: 'done',
  UPLOADING: 'uploading',
  ABORTED: 'aborted',
  RESTARTED: 'restarted',
  REMOVED: 'removed',
  STARTED: 'started',
  READY: 'ready',
  PREPARING: 'preparing',
  GETTING_UPLOAD_PARAMS: 'getting_upload_params',
  HEADERS_RECEIVED: 'headers_received',
  REJECTED_FILE: 'rejected_file_type',
};

export const CSV_FILE_UPLOAD_CATEGORIES = {
  SOC_RECIPE: 'recipe',
  ASSET_CRITICALITY: 'assetCriticality',
  ASSET_INTELLIGENCE: 'assetHierarchy',
  FAULT_CODES: 'faultCodes',
  CELL_CRITICALITY: 'cellCriticality',
  FH_THROUGHPUT: 'factoryHealthThroughput',
  FH_WIP: 'factoryHealthWip',
};

export const HTTP_METHODS = {
  PUT: 'PUT',
  POST: 'POST',
  GET: 'GET',
  DELETE: 'DELETE',
};

export const ALLOWED_FILE_TYPES = {
  CSV: '.csv',
  IMAGE: 'image/*',
  DOC: '.doc',
  DOCX: '.docx',
  XLS: '.xls',
  XLXS: '.xlsx',
  TXT: '.txt',
};

export const WEEKLY_ROTATIONS_CSV_FILE_NAME = 'weekly-rotations-';
export const DAILY_ROTATIONS_CSV_FILE_NAME = 'daily-rotations-';
export const ASSET_INTELLIGENCE_CSV_FILE_NAME = 'asset-intelligence-parameters';
export const ASSET_CRITICALITY_CSV_FILE_NAME = 'asset-criticality';
export const CELL_CRITICALITY_CSV_FILE_NAME = 'cell-criticality';
export const FAULT_CODES_CSV_FILE_NAME = 'faultCode-template';

export const ERROR_CODES = {
  CSV_NOT_UPLOADED: 204,
};

export const PRODUCTION_DAY_WEEKS = [
  'production_Day_1',
  'production_Day_2',
  'production_Day_3',
  'production_Day_4',
  'production_Day_5',
  'production_Day_6',
  'production_Day_7',
];

export const PFC_REDUCER_CONSTANTS = {
  SET_FINANCIAL_CALCULATION_DATA: 'SET_FINANCIAL_CALCULATION_DATA',
  CHANGE_VALUE: 'CHANGE_VALUE',
  CHANGE_UNIT: 'CHANGE_UNIT',
  CHANGE_SOLD_OUT: 'CHANGE_SOLD_OUT',
};

export const CBM_TYPES = {
  REFRESH_RATE: 'refreshRate',
  CRITICALITY_RATINGS: 'criteriaForCriticalityRatings',
  ASSET_CRITICALITY: 'assetCriticality',
  RISK_CATEGORY_THRESHOLDS: 'riskCategoryThresholds',
  STANDARD_OPERATING_CONDITIONS: 'standardOperatingConditions',
  CELL_CRITICALITY: 'CELL_CRITICALITY',
};

export const PARENT_FINANCIAL_CALCULATION_CONSTANTS = {
  ACCESSORS: {
    LABEL: 'label',
    VALUE: 'value',
    UOM: 'UoM',
  },
};

export const CONFIG_DONE_CONTAINER_ID = 'configDoneContainer';

export const RISK_CATEGORY_REDUCER_CONSTANTS = {
  UPDATE_BOUNDS: 'UPDATE_BOUNDS',
  VALIDATE_DATA: 'VALIDATE_DATA',
  OPEN_POPOVER: 'OPEN_POPOVER',
  CLOSE_POPOVER: 'CLOSE_POPOVER',
  RESET_SENTINEL: 'RESET_SENTINEL',
};

const TIMEZONES = {
  WASHINGTON: 'Eastern Standard Time',
  LA: 'Pacific Standard Time',
  CHICAGO: 'Central Standard Time',
  PHOENIX: 'Mountain Standard Time',
  ALASKA: 'Alaska Standard Time',
  HONOLULU: 'Hawaii-Aleutian Standard Time',
};

const TZ_NAME = {
  CHICAGO: 'America/Chicago',
  LA: 'America/Los_Angeles',
  PHOENIX: 'America/Phoenix',
  WASHINGTON: 'America/New_York',
  ALASKA: 'America/Anchorage',
  HONOLULU: 'Pacific/Honolulu',
};

export const LABEL_ZONE_MAPPING = {
  [TZ_NAME.WASHINGTON]: TIMEZONES.WASHINGTON,
  [TZ_NAME.LA]: TIMEZONES.LA,
  [TZ_NAME.CHICAGO]: TIMEZONES.CHICAGO,
  [TZ_NAME.PHOENIX]: TIMEZONES.PHOENIX,
  [TZ_NAME.ALASKA]: TIMEZONES.ALASKA,
  [TZ_NAME.HONOLULU]: TIMEZONES.HONOLULU,
};

export const INTRA_CELL = 'Intra-cell';

export const CREATE_PLANT = {
  PLANT_NAME: 'plantName',
  BUSINESS_UNIT: 'businessUnit',
  CITY: 'city',
  STATE: 'stateCode',
  ZIPCODE: 'zipcode',
  STREET: 'street',
  DEFAULT_PLACEHOLDER_VALUE: 'emptyValue',
};

export const WEB_SOCKET = {
  UPDATE_ASSET_HIERARCHY_CSV: 'updateAssetHierarchyCsv',
  UPDATE_SOC: 'saveSetpointValues',
};

export const WINDOW_EVENTS = {
  BEFORE_UNLOAD: 'beforeunload',
};

export const UPLOAD_ERROR_CODE = {
  INVALID_TYPE: 'file-invalid-type',
  LARGE: 'file-too-large',
  MANY_FILES: 'too-many-files',
};

export const CREATE_PLANT_UPLOAD = {
  MAX_SIZE: 100000,
  MAX_FILE: 1,
};

export const RBAC_CONSTANTS = {
  TEMPORARY_EMAIL_ID: 'harshraj@deloitte.com',
  LOGOUT: 'Logout',
  LANDING_PAGES: {
    GLOBE: 'Globe',
    PERFORMANCE_MANAGEMENT: 'Performance Management',
    ASSET_MANAGEMENT: 'Asset Management',
    ORDER_MANAGEMENT: 'Order Management',
  },

  LEVELS: {
    PLANT: 'Plant',
    BUSINESS_UNIT: 'Business Unit',
    GLOBAL: 'Global',
  },

  PERMISSIONS: {
    // Arrange in heirachy
    VIEW_ONLY: 'View Only',
    EDIT_ONLY: 'Edit Only',
    EDIT_AND_CONFIGURE: 'Edit and Configure',
    SYSTEM_ADMIN: 'System Admin',
  },
};

export const CBM_CONSTANTS = {
  FILTER: 'Filter',
  LOADING_WATCHLIST: 'Loading Watch Lists',
  WATCH_LIST_TITLES: ['Watch List', 'Operating Conditions'],
  WATCH_LIST_TITLE_KEYS: ['Cell', 'Asset', 'Parameter'],
  TOP_HEADER: 'Equipment Management',
  TAB_ITEMS_FIRST_LEVEL: [
    {
      label: 'Manufacturing Equipment',
      value: 0,
      disabled: false,
    },
    {
      label: 'Building Equipment',
      value: 1,
      disabled: false,
    },
  ],

  TAB_ITEMS_SECOND_LEVEL: [
    {
      label: 'Watch Lists',
      value: 0,
      disabled: false,
    },
    {
      label: 'Operating Conditions',
      value: 1,
      disabled: false,
    },
  ],
  RISK_CATEGORY_LABELS: [
    'Catastrophic',
    'Major',
    'Moderate',
    'Minor',
    'Insignificant',
  ],
  CELL_LIST_TABLE_HEADERS: [
    { NAME: 'Priority', WIDTH: '8%' },
    { NAME: 'Area', WIDTH: '12%' },
    { NAME: 'Line', WIDTH: '12%' },
    { NAME: 'Zone', WIDTH: '12%' },
    { NAME: 'Cell', WIDTH: '40%' },
    { NAME: 'Risk Category', WIDTH: '9%' },
    { NAME: 'View Details', WIDTH: '7%' },
  ],
  ASSET_LIST_TABLE_HEADERS: [
    { NAME: 'Priority', WIDTH: '8%' },
    { NAME: 'Area', WIDTH: '12%' },
    { NAME: 'Line', WIDTH: '12%' },
    { NAME: 'Zone', WIDTH: '12%' },
    { NAME: 'Cell', WIDTH: '12%' },
    { NAME: 'Asset', WIDTH: '26%' },
    { NAME: 'Risk Category', WIDTH: '9%' },
    { NAME: 'View Details', WIDTH: '7%' },
  ],
  CELL_LIST_TABLE_HEADERS_KEYS: [
    'priority',
    'area',
    'line',
    'zone',
    'cell',
    'riskCategory',
  ],
  ASSET_LIST_TABLE_HEADERS_KEYS: [
    'priority',
    'area',
    'line',
    'zone',
    'cell',
    'asset',
    'riskCategory',
  ],
  PARAMETER_LIST_TABLE_HEADERS: [
    { NAME: 'Priority', WIDTH: '7.5%' },
    { NAME: 'Area', WIDTH: '8.5%' },
    { NAME: 'Line', WIDTH: '10%' },
    { NAME: 'Zone', WIDTH: '10%' },
    { NAME: 'Cell', WIDTH: '10.5%' },
    { NAME: 'Asset', WIDTH: '10.5%' },
    { NAME: 'Parameter', WIDTH: '10.5%' },
    { NAME: 'Setpoint Target', WIDTH: '8.5%' },
    { NAME: 'Present Value', WIDTH: '8%' },
    { NAME: 'Risk Category', WIDTH: '9%' },
    { NAME: 'View Details', WIDTH: '' },
  ],
  PARAMETER_LIST_TABLE_HEADERS_KEYS: [
    'priority',
    'area',
    'line',
    'zone',
    'cell',
    'asset',
    'parameter',
    'setpointTarget',
    'presentValue',
    'riskCategory',
  ],
  CONSTRAINT_TYPE: 'Constraint Type',
  CONSTRAINT_DURATION: 'Constraint Duration',
  AREAS: 'Areas',
  LINES: 'Lines',
  ZONES: 'Zones',
  CELLS: 'Cells',
  ASSETS: 'Assets',
  AREA: 'Area',
  LINE: 'Line',
  ZONE: 'Zone',
  CELL: 'Cell',
  ASSET: 'Asset',
  PARAMETER_TYPE: 'Parameter Type',
  PARAMETER: 'Parameter',
  PART_NAME: 'Part Name',
  PART_NUMBER: 'Part No',
  UOM: 'UoM',
  RECIPE: 'Recipe',
  SETPOINT_LL: 'LL',
  SETPOINT_TARGET: 'Target',
  SETPOINT_UL: 'UL',
  SETPOINT_VALUE: 'SV',
  PRESENT_VALUE: 'PV',

  ROWS_PER_PAGE: 'Rows per page',
  PARAMETER_ENTITY_ID: 'parameterEntityId',
  PARAMETER_TYPE_ENTITY_ID: 'parameterTypeEntityId',
  ASSET_ENTITY_ID: 'assetEntityId',
  CELL_ENITY_ID: 'cellEntityId',
  ZONE_ENTITY_ID: 'zoneEntityId',
  LINE_ENTITY_ID: 'lineEntityId',
  AREA_ENTITY_ID: 'areaEntityId',

  TOOLTIPS: {
    LL: {
      title: 'Setpoint Lower Limit',
      description:
        'Setpoint lower bound threshold as defined in the Standard Operating Conditions configuration',
    },
    TARGET: {
      title: 'Setpoint Target',
      description:
        'Setpoint target as defined in the Standard Operating Conditions configuration',
    },
    UL: {
      title: 'Setpoint Upper Limit',
      description:
        'Setpoint upper bound threshold as defined in the Standard Operating Conditions configuration',
    },
    SV: {
      title: 'Setpoint Value',
      description:
        'The value that the control system is aiming to reach and maintain for a parameter on the equipment',
    },
    PV: {
      title: 'Present Value',
      description:
        'The value of a parameter at the current point in time as measured by a sensor',
    },
  },
};

export const CONSTRAINT_VIEWS = {
  DEFAULT: 'ConstraintManagement',
};

export const CONSTRAINT_MISC_SETTINGS_CONSTANTS = {
  ACCESSORS: {
    PARAMETER: 'parameter',
    VALUE: 'value',
  },
};

export const CONSTRAINT_MISC_SETTINGS_REDUCER_CONSTANTS = {
  SET_DATA: 'SET_DATA',
  UPDATE_VALUE: 'UPDATE_VALUE',
  PUT_UPDATE_SUCCESSFUL: 'PUT_UPDATE_SUCCESSFUL',
};

export const ORDER_MANAGEMENT_CONSTANTS = {
  HEADER: 'Order Management',
  TAB_ITEMS: [
    {
      label: 'Constraint Mgmt.',
      value: 0,
      disabled: false,
    },
    {
      label: 'Dynamic Order Mgmt.',
      value: 1,
      disabled: false,
    },
  ],
  ACTIONS_TAB_ITEMS: [
    {
      label: 'View Equipment Fault',
      value: 0,
      disabled: false,
    },
    {
      label: 'View Work Orders',
      value: 1,
      disabled: false,
    },
  ],
};

export const CONSTRAINT_MANAGEMENT_CONSTANTS = {
  CONSTRAINT_MANAGEMENT_TITLE: 'Constraint Mgmt.',
  CONSTRAINT_STATUS: 'Constraint Status',
  OPEN_CONSTRAINTS: 'Open Constraints',
  SNOOZED_CONSTRAINTS: 'Snoozed Constraints',
  CLOSED_CONSTRAINTS: 'Closed Constraints',
  CONSTRAINT_DURATION_METRICS: 'Constraint Duration Metrics',
  HOUR: 'hr',
  MINS: 'mins',
  ZERO: '0',
  NA: 'N/A',
  CONFIRM: 'Confirm',
  CORRECTIVE_ACTIONS: 'Corrective Actions',
  WORK_ORDER_CREATED: 'Work Order Created',
  ACTIONS: 'Actions',
  START_TIME_OPEN_OR_SNOOZED: 'Maint. Start Time',
  START_TIME_CLOSED: 'Maint. Actual Start Time',
  END_TIME_OPEN_OR_SNOOZED: 'Maint. Planned End Time',
  END_TIME_CLOSED: 'Maint. Actual End Time',
  PRIORITY: 'Priority',
  TIME_STAMP: 'Time Stamp',
  CONSTRAINT_TYPE: 'Constraint Type',
  LOADING_DATA: 'Loading data',
  NO_SHIFT: 'No shift is currently working',
  TAB_TYPE_KEYS: [
    'allConstraints',
    'equipmentConstraints',
    'materialConstraints',
  ],
  CARD_DATA: [
    {
      HEADING: 'openConstraintsDuration',
      CAPTION: 'targetOpenConstraintDuration',
      SUBTITLE_1: 'Total Duration',
      SUBTITLE_2: 'Open Constraints',
    },
    {
      HEADING: 'avgConstraintResolutionTime',
      CAPTION: 'targetAvgConstraintResolutionTime',
      SUBTITLE_1: 'Average Resolution Time',
      SUBTITLE_2: 'Closed Constraints',
    },
    {
      HEADING: 'forecastedLabourTime',
      CAPTION: 'targetForecastedLabourTime',
      SUBTITLE_1: 'Forecasted Labor To Resolution',
      SUBTITLE_2: 'Open Constraints',
    },
  ],
  TAB_ITEMS: [
    {
      label: 'All Constraints',
      value: 0,
      disabled: false,
    },
    {
      label: 'Equipment Constraints',
      value: 1,
      disabled: false,
    },
    {
      label: 'Material Constraints',
      value: 2,
      disabled: false,
    },
  ],
  HEADER_CONSTANTS: {
    REFRESH_RATE: 'Refresh Rate',
    TIME_RANGE: 'Time Range',
    ACTUALS: 'Actuals',
    TARGETS: 'Targets',
    ACTUAL_VALUE: 'N',
    TARGET_VALUE: '|N',
  },
  SNOOZE_POPUP: ['hr', 'min'],
  CONSTRAINTS_DROPDOWN_INIT_REFERENCE: [
    {
      entityId: '0-15',
      entityName: '<= 15 mins',
    },
    {
      entityId: '15-30',
      entityName: '15> - <=30 mins',
    },
    {
      entityId: '30-60',
      entityName: '30> - <=60 mins',
    },
    {
      entityId: '60-720',
      entityName: '1> - <=12 hrs',
    },
    {
      entityId: '720-1200',
      entityName: '>12 hrs',
    },
  ],
  WORK_ORDER_INFO: [
    { label: 'Work Order #', key: 'workOrder', size: 4 },
    { label: 'Department', key: 'department', size: 4 },
    { label: 'Assigned to', key: 'assignedTo', size: 4 },
    { label: 'Equipment Code (#)', key: 'equipmentCode', size: 4 },
    { label: 'Trade', key: 'trade', size: 4 },
    { label: 'Work Order Type', key: 'workOrderType', size: 4 },
    { label: 'Work Order Description', key: 'workOrderDescription', size: 12 },
    { label: 'Activities', key: 'activities', size: 12 },
  ],
  LOADING_WORK_ORDERS: 'Loading Work Orders',
  NO_DATA_AVAILABLE: 'No Data Available',
};
export const DYNAMIC_ORDER_MANAGEMENT_CONSTANTS = {
  HEADER_TITLE: 'Dynamic Order Management',
  HEADER_SUBTITLES: [
    {
      label: 'Frozen Order Status Time',
      key: 'frozenOrderStatusTime',
      size: 2,
    },
    { label: 'SFF Editable Time Window', key: 'editableTimeWindow', size: 4 },
    { label: 'Approval Time', key: 'approvalTime', size: 2 },
    { label: 'Plant Time', key: 'plantTime', size: 2 },
  ],
  START_TIME: 'Start Time',
  DURATION: 'Duration',
  TAB_ITEMS: [
    {
      label: 'Production Orders',
      value: 0,
      disabled: false,
    },
    {
      label: 'Production Schedule',
      value: 1,
      disabled: false,
    },
  ],
  DROPDOWN_LABELS: [
    'Scheduled Area',
    'Scheduled Line',
    'Scheduled Zone',
    'Time Range',
  ],
  SEQUENCE: 'Sequence',
  PRIORITY_RATING: 'Priority Rating',
  ORDER: 'Order',
  ORDER_DESCRIPTION: 'Order Description',
  CURRENT_LINE: 'Current Line',
  CURRENT_ZONE: 'Current Zone',
  CURRENT_CELL: 'Current Cell',
  ORDER_QUANITY: 'Order Quantity',
  ORDER_STATUS: 'Order Status',
  LOADING: 'Loading',
  SAVE: 'Save',
  EDIT: 'Edit',
  APPLY: 'Apply',
  SELECT_VALUE: 'Select Value',
  NEXT_7_DAYS: 'Next 7 days',
};

export const ADD_EDIT_TASK_MODE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export const ADD_EDIT_PLANT_MODAL_REDUCER_CONSTANTS = {
  CHANGE_SELECTED_PLANT: 'CHANGE_SELECTED_PLANT',
  CHANGE_SEARCH_OPTION: 'CHANGE_SEARCH_OPTION',
  CLICK_CREATE_NEW_PLANT: 'CLICK_CREATE_NEW_PLANT',
  EDIT_EXISTING_PLANT: 'EDIT_EXISTING_PLANT',
  UPDATE_PLANT_DATA: 'UPDATE_PLANT_DATA',
  UPDATE_PLANT_IMAGE: 'UPDATE_PLANT_IMAGE',
  SHOW_ERROR: 'SHOW_ERROR',
  CLEAR_FORM: 'CLEAR_FORM',
  LOAD_PLANTS_LIST: 'LOAD_PLANTS_LIST',
};

export const INITIAL_SETUP_TABS = {
  COMPANY: 'Create Company',
  BUSINESS_UNITS: 'Create Business Units',
  PLANTS: 'Create Plant',
};

export const INITIAL_COMPANY_SETUP_REDUCER_CONSTANTS = {
  NEXT_STEP: 'NEXT_STEP',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  OPEN_MODAL: 'OPEN_MODAL',
  UPDATE_COMPANY_DATA: 'UPDATE_COMPANY_DATA',
  UPDATE_PLANT_DATA: 'UPDATE_PLANT_DATA',
  ADD_NEW_BUSINESS_UNITS: 'ADD_NEW_BUSINESS_UNITS',
  UPDATE_BUSINESS_UNITS: 'UPDATE_BUSINESS_UNITS',
  UPDATE_PLANT_IMAGE: 'UPDATE_PLANT_IMAGE',
  VALIDATE_PLANT_DATA: 'VALIDATE_PLANT_DATA',
  VALIDATE_COMPANY_DATA: 'VALIDATE_COMPANY_DATA',
  COMAPNY_DATA_SHOW_ERROR: 'COMAPNY_DATA_SHOW_ERROR',
  BUSINESS_UNITS_DATA_SHOW_ERROR: 'BUSINESS_UNITS_DATA_SHOW_ERROR',
  PLANTS_DATA_SHOW_ERROR: 'PLANTS_DATA_SHOW_ERROR',
  SET_COMPANY_DATA: 'SET_COMPANY_DATA',
  SET_BUSINESS_UNITS: 'SET_BUSINESS_UNITS',
  CLEAR_PLANT_FORM_AND_DISPLAY_ANOTHER: 'CLEAR_PLANT_FORM_AND_DISPLAY_ANOTHER',
  DISPLAY_MODAL_CREATE_PLANT: 'DISPLAY_MODAL_CREATE_PLANT',
  CLOSE_ALL_MODAL: 'CLOSE_ALL_MODAL',
};

export const INITIAL_COMPANY_SETUP = {
  COMPANY_INITIAL_VALUES: {
    value: '',
    error: MESSAGE_STRINGS['InitialCompanySetup.company.errorMessage.empty'],
    showError: false,
    isUpdated: false,
  },
  BUSINESS_UNIT_INITIAL_VALUES: [
    {
      value: '',
      error:
        MESSAGE_STRINGS['InitialCompanySetup.businessUnit.errorMessage.empty'],
      showError: false,
      isUpdated: false,
      id: '1',
    },
  ],
  PLANTS_INITIAL_VALUES: {
    plantName: {
      value: '',
      error:
        MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
      showError: false,
    },
    businessUnit: {
      value: 'emptyValue',
      error:
        MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.businessUnit'],
      showError: false,
    },
    street: {
      value: '',
      error:
        MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
      showError: false,
    },
    city: {
      value: '',
      error:
        MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
      showError: false,
    },
    zipcode: {
      value: '',
      error:
        MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
      showError: false,
    },
    stateCode: {
      value: 'emptyValue',
      error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.state'],
      showError: false,
    },
    imageFile: [],
  },
};

export const ADD_EDIT_BU_REDUCER_CONSTANTS = {
  ADD_NEW_BUSINESS_UNITS: 'ADD_NEW_BUSINESS_UNITS',
  UPDATE_BUSINESS_UNITS: 'UPDATE_BUSINESS_UNITS',
  LOAD_BUSINESS_UNITS: 'LOAD_BUSINESS_UNITS',
  SHOW_ERRORS: 'SHOW_ERRORS',
};

export const ADD_EDIT_PLANT_PRISTINE_STATE = {
  plantName: {
    value: '',
    showError: false,
    error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
    isUpdated: false,
  },
  city: {
    value: '',
    showError: false,
    error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
    isUpdated: false,
  },
  street: {
    value: '',
    showError: false,
    error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
    isUpdated: false,
  },
  businessUnit: {
    value: CREATE_PLANT.DEFAULT_PLACEHOLDER_VALUE,
    showError: false,
    error:
      MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.businessUnit'],
    isUpdated: false,
  },
  stateCode: {
    value: CREATE_PLANT.DEFAULT_PLACEHOLDER_VALUE,
    showError: false,
    error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.state'],
    isUpdated: false,
  },
  zipcode: {
    value: '',
    showError: false,
    error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
    isUpdated: false,
  },
  imageFile: [],
  imageFileUpdated: false,
  plantId: '',
};

export const APP_CONTEXT = {
  COMPANY_DATA: 'COMPANY_DATA',
  UPDATE_COMPANY_NAME: 'UPDATE_COMPANY_NAME',
};

export const USER_PROGRESS_INITIAL_SETUP = {
  COMPANY_NOT_CREATED: 0,
  BUSINESS_UNITS_NOT_CREATED: 1,
  PLANTS_NOT_CREATED: 2,
  DONE: 3,
};

export const CONSTRAINT_FAULT_CODES_REDUCER_CONSTANTS = {
  SET_SHOW_UPLOAD_BUTTON: 'SET_SHOW_UPLOAD_BUTTON',
  SET_IS_UPLOAD_URL_FETCHING: 'SET_IS_UPLOAD_URL_FETCHING',
  SET_PROCESS_CSV_ERROR_MESSAGE: 'SET_PROCESS_CSV_ERROR_MESSAGE',
  SET_SHOW_UPLOAD_SUCCESS_DIALOG_BOX: 'SET_SHOW_UPLOAD_SUCCESS_DIALOG_BOX',
  SET_CSV_PROCESSING: 'SET_CSV_PROCESSING',
  SET_CSV_PROCESSING_ERROR: 'SET_CSV_PROCESSING_ERROR',
  SET_TEMPLATE_DOWNLOAD_URL: 'SET_TEMPLATE_DOWNLOAD_URL',
  ON_MESSAGE: 'ON_MESSAGE',
  ON_SECONDARY_UPLOAD: 'ON_SECONDARY_UPLOAD',
  UPLOAD_URL_FETCHING_START: 'UPLOAD_URL_FETCHING_START',
  SET_META_DATA: 'SET_META_DATA',
};

export const CONFIG_ORDER_MANAGEMENT_ACCORDIONS = {
  MISC_SETTINGS: 'MISC_SETTINGS',
  SYSTEM_INTEGRATIONS: 'SYSTEM_INTEGRATIONS',
  CHANGEOVER_TIME: 'CHANGEOVER_TIME',
};

export const CONFIG_ORDER_MANAGEMENT = {
  MISC_SETTINGS: {
    ACCESSORS: {
      PARAMETER: 'label',
      VALUE: 'value',
      TIME: 'unit',
    },
    REFRESH_RATE: 'Refresh Rate',
    FROZEN_ORDER: 'Configured Time for Frozen Orders',
    EDITABLE_START_TIME: 'Editable Time Window Start Time',
    EDITABLE_DURATION: 'Editable Time Window Duration',
    APPROVAL_TIME: 'Approval Time',
    CHANGE_OPEN_ACCORDION: 'CHANGE_OPEN_ACCORDION',
  },
  SYSTEM_INTEGRATIONS: {
    ACCESSORS: { LABEL: 'label', VALUE: 'value' },
    VIEW_BY: 'VIEW_BY',
    DEFAULT_VIEW_BY_VALUE: 'emptyValue',
  },
  CHANGEOVER_TIME: {
    ACCESSORS: { LABEL: 'zoneName', VALUE: 'value' },
  },
};

export const ORDER_MANAGEMENT_MISC_SETTINGS_REDUCER_CONSTANTS = {
  UPDATE_DATA: 'UPDATE_DATA',
  UPDATE_REFRESH_RATE_VALUE: 'UPDATE_REFRESH_RATE_VALUE',
  UPDATE_REFRESH_RATE_UNITS: 'UPDATE_REFRESH_RATE_UNITS',
  SET_DATA: 'SET_DATA',
  SHOW_ERROR: 'SHOW_ERROR',
};

export const ORDER_MANAGEMENT_SYSTEM_INTEGRATIONS_REDUCER_CONSTANTS = {
  UPDATE_DATA: 'UPDATE_DATA',
  SET_DATA: 'SET_DATA',
};
export const OEE_IDEAL_RUN_RATE_CONSTANTS = {
  ACCESSORS: {
    LABEL: 'lineName',
    VALUE: 'idealRunRate',
    UOM: 'uom',
  },
  DEFAULT_PLACEHOLDER_VALUE: 'SELECT_UOM',
};

export const OEE_IDEAL_RUN_RATE_REDUCER_CONSTANTS = {
  SET_DATA: 'SET_DATA',
  UPDATE_IDEAL_RUN_RATE: 'UPDATE_IDEAL_RUN_RATE',
  UPDATE_UOM_DATA: 'UPDATE_UOM_DATA',
};

export const RISK_SELECTION_GRADIENTS = {
  DEFAULT: '#95CA3C 0%, #FF9900 50%, #DA291C 100%',
  PARAMETER_RISK_FACTOR_THRESHOLD:
    '#33F0FF 8.68%, #95CA3C 29.51%, #FFCD00 49.83%, #ED8B00 70.14%, #DA291C 90.97%',
};

export const NOTIFICATION_ADD_REMOVE_REDUCER_CONSTANTS = {
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  RESET_STATE: 'RESET_STATE',
  SET_FILTER_META: 'SET_FILTER_META',
  TOGGLE_FILTER_STATE: 'TOGGLE_FILTER_STATE',
  APPLY_FILTER_STATE: 'APPLY_FILTER_STATE',
  RESET_FILTER_STATE: 'RESET_FILTER_STATE',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  HANDLE_FILTER_CLOSE: 'HANDLE_FILTER_CLOSE',
  CANCEL_ACTION: 'CANCEL_ACTION',
  RESET_GLOBAL_FILTERS: 'RESET_GLOBAL_FILTERS',
};

export const NOTIFICATION_CONFIGURATION_REDUCER = {
  SET_DURATION_VALUE: 'SET_DURATION_VALUE',
  SET_DURATION_ERROR: 'SET_DURATION_ERROR',

  SET_SNOOZE_TIME_VALUE: 'SET_SNOOZE_TIME_VALUE',
  SET_SNOOZE_TIME_UOM: 'SET_SNOOZE_TIME_UOM',
  SET_SNOOZE_TIME_ERROR: 'SET_SNOOZE_TIME_ERROR',

  SET_PERSIST_TIME_VALUE: 'SET_PERSIST_TIME_VALUE',
  SET_PERSIST_TIME_ERROR: 'SET_PERSIST_TIME_ERROR',

  SET_NOTIFICATION_SETTINGS: 'SET_NOTIFICATION_SETTINGS',
  RESET_NOTIFICATION_SETTINGS: 'RESET_NOTIFICATION_SETTINGS',
  UPDATE_OLD_NOTIFICATION_SETTINGS: 'UPDATE_OLD_NOTIFICATION_SETTINGS',

  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  UPDATE_NOTIFICATION_TYPE: 'UPDATE_NOTIFICATION_TYPE',
  UPDATE_MUTE_VALUE: 'UPDATE_MUTE_VALUE',
  UPDATE_RECURRENCE_SWITCH: 'UPDATE_RECURRENCE_SWITCH',
  UPDATE_RECURRENCE_VALUE: 'UPDATE_RECURRENCE_VALUE',
  UPDATE_RECURRENCE_UOM: 'UPDATE_RECURRENCE_UOM',
  UPDATE_NOTIFICATON_ROLES_ASSIGNED: 'UPDATE_NOTIFICATON_ROLES_ASSIGNED',
  SET_ACTIVE_NOTIFICATIONS_ERRORS: 'SET_ACTIVE_NOTIFICATIONS_ERRORS',

  TOGGLE_ADD_REMOVE_MODAL_VISIBILITY: 'TOGGLE_ADD_REMOVE_MODAL_VISIBILITY',
  SET_EDITING_MODE: 'SET_EDITING_MODE',
  SET_ADDITIONAL_SETTINGS_CONTEXT: 'SET_ADDITIONAL_SETTINGS_CONTEXT',
  HANDLE_OPERATIONS_AREA_FILTER_TOGGLE: 'HANDLE_OPERATIONS_AREA_FILTER_TOGGLE',
  HANDLE_OPERATIONS_AREA_FILTER_APPLY: 'HANDLE_OPERATIONS_AREA_FILTER_APPLY',
  HANDLE_OPERATIONS_AREA_FILTER_RESET: 'HANDLE_OPERATIONS_AREA_FILTER_RESET',
  HANDLE_FILTER_CLOSE: 'HANDLE_FILTER_CLOSE',
  HANDLE_OPERATIONAL_FILTER_CLOSE: 'HANDLE_OPERATIONAL_FILTER_CLOSE',
  HANDLE_OPERATIONS_AREA_SELECTED_REMOVE:
    'HANDLE_OPERATIONS_AREA_SELECTED_REMOVE',
  HANDLE_OPERATIONS_AREA_SEARCH_TERM_CHANGE:
    'HANDLE_OPERATIONS_AREA_SEARCH_TERM_CHANGE',
  HANDLE_OPERATIONAL_FILTER_OPEN: 'HANDLE_OPERATIONAL_FILTER_OPEN',
  ADD_REMOVE_SAVE_ACTION: 'ADD_REMOVE_SAVE_ACTION',
  TOGGLE_FILTER_STATE: 'TOGGLE_FILTER_STATE',
  APPLY_FILTER_STATE: 'APPLY_FILTER_STATE',
  RESET_FILTER_STATE: 'RESET_FILTER_STATE',
  SET_FILTER_META: 'SET_FILTER_META',
  RESET_ALL_FILTER: 'RESET_ALL_FILTER',
  RESET_ON_UPDATE: 'RESET_ON_UPDATE',
  RESET_GLOBAL_FILTER: 'RESET_GLOBAL_FILTER',

  OPEN_SETTING_ACCORDION: 'OPEN_SETTING_ACCORDION',
};

export const NOTIFICATION_CONFIGS = {
  NOTIFICATION_TIMING: 'NOTIFICATION_TIMING',
  NOTIFICATION_TRIGGER_SETTINGS: 'NOTIFICATION_TRIGGER_SETTINGS',
};

export const ACTIVE_NOTIFICATIONS_TABLE_HEADERS = {
  FUNCTIONAL_AREA: 'functionalArea',
  NOTIFICATION_TRIGGER: 'triggerName',
  ROLES_ASSIGNED: 'rolesAssigned',
  NOTIFICATION_TYPE: 'notificationType',
  RECURRENCE: 'recurrence',
  ADDITIONAL_SETTINGS: 'additionalSettings',
  MUTE: 'isMuted',
};

export const TIME_UOM = {
  DAYS: 'DAYS',
  HOURS: 'HOURS',
  MINUTES: 'MINUTES',
  SECONDS: 'SECONDS',
};

export const NOTIFICATION_TIMINGS_TYPES = {
  DURATION_FOR_BANNER: 'DURATION_VIEW',
  SNOOZE_TIME_FOR_ALERT: 'SNOOZE_TIME',
  NOTIFICATION_CENTRE_PERSIST: 'PERSIST_NOTIFICATION_DURATION',
};

export const NOTIFICATION_TYPE_DEFAULT_VALUES = {
  key: 'selectType',
  value: 'Select Type',
};

export const ADDITIONAL_NOTIFICATION_SETTINGS = {
  ASSET_CRITICALITY_THRESHOLD: 'ASSETCRITICALITYTHRESHOLD',
  ASSET_RISK_CATEGORY_TRESHOLD: 'ASSETRISKCATEGORYTHRESHOLD',
  PARAMETER_RISK_FACTOR_THRESHOLD: 'PARAMTERRISKCATEGORYTHRESHOLD',
  CELL_CRITICALITY_TRESHOLD: 'CELLCRITICALITYTHRESHOLD',
  CELL_RISK_CATEGORY_TRESHOLD: 'CELLRISKCATEGORYTHRESHOLD',
};

// mock
export const ASSET_CRIT_TRESHOLD = [
  {
    id: 1,
    name: 'Lowest Risk',
    tooltipMessage: 'Manual Workaround available',
  },
  {
    id: 2,
    name: '',
    tooltipMessage: 'Manual Workaround available',
  },
  {
    id: 3,
    name: '',
    tooltipMessage: 'Manual Workaround available',
  },
  {
    id: 4,
    name: '',
    tooltipMessage: 'Manual Workaround available',
  },
  {
    id: 5,
    name: '',
    tooltipMessage: 'Manual Workaround available',
  },
  {
    id: 6,
    name: '',
    tooltipMessage: 'Manual Workaround available',
  },
  {
    id: 7,
    name: '',
    tooltipMessage: 'Manual Workaround available',
  },
  {
    id: 8,
    name: 'Highest Risk',
    tooltipMessage: 'Medium Term shutdown with difficult fear',
  },
];

// mock
export const PARAMETER_RISK_THRESHOLD = [
  {
    id: 1,
    name: 'Insignificant',
    tooltipMessage: '',
  },
  {
    id: 2,
    name: 'Minor',
    tooltipMessage: '',
  },
  {
    id: 3,
    name: 'Moderate',
    tooltipMessage: '',
  },
  {
    id: 4,
    name: 'Major',
    tooltipMessage: '',
  },
  {
    id: 5,
    name: 'Catastrophic',
    tooltipMessage: '',
  },
];

export const ORDER_MANAGEMENT_CHANGEOVER_TIME_REDUCER_CONSTANTS = {
  UPDATE_DATA: 'UPDATE_DATA',
  SET_DATA: 'SET_DATA',
};

export const TIME_PICKER_FIELD = {
  MASK_VALUE: '__:__',
};

export const ADDITIONAL_SETTINGS_REDUCER_CONSTANTS = {
  SET_DATA: 'SET_DATA',
  ROLE_BASED_ACCESS_UPDATE: 'ROLE_BASED_ACCESS_UPDATE',
  ROLE_BASED_ACCESS_SHOW_VALIDATION: 'ROLE_BASED_ACCESS_SHOW_VALIDATION',
  OEE_DURATION_UPDATE: 'OEE_DURATION_UPDATE',
  OEE_UOM_UPDATE: 'OEE_UOM_UPDATE',
  CBM_SET_NEW_TRESHOLD: 'CBM_SET_NEW_TRESHOLD',
};

export const DDS_DEMO_STATES = {
  CREATED: 'Created',
  PREPARING: 'Preparing',
  READY: 'Ready',
  IN_PROGRESS: 'InProgress',
  PAUSED: 'Paused',
};

export const DDS_DEMO_CONTROLS = {
  PLAY: 'play',
  PAUSE: 'pause',
  STOP: 'stop',
};

export const QUERY_KEYS = {
  GET_RUNNING_DEMO_LIST: "GET_RUNNING_DEMO_LIST",
  PUT_CONFIGURE_DEMO_STATE: "PUT_CONFIGURE_DEMO_STATE",
};

export const APP_URL = {
  SOC_CONFIG: "/config/standard_operating_conditions",
  CONFIG: "/config",
};
